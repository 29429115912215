import React, { useEffect, useRef, useState } from 'react';
import style from './ActionPlan.module.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBasketShopping, faBuilding, faLandmark, faNotesMedical, faCheck } from '@fortawesome/free-solid-svg-icons';

let actionText = `AHORRA a pour but d'accompagner les professionnels médicaux dans l'optimisation et le développement de 4 principaux pôles structurants et financiers.`;

function formatTextWithLineBreaks(text) {
    return text.split('\n').map((str, index, array) => (
        str !== '' ? <React.Fragment key={index}>{str}{index !== array.length - 1 && <br />}</React.Fragment> : <br key={index} />
    ));
}

function ActionPlan() {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const currentRef = ref.current;
        const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            });
        },
        {
            root: null,
            threshold: 0.5
        });

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, [ref]);

  return (
    <div className={style.mainContainer} ref={ref}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', textAlign: 'center', justifyContent: 'center' }}>
            <span className={`${style.title} ${style.titleWithUnderline}`}>
                Nos <span style={{ fontWeight: 'bold' }}>champs d'action</span>
                <span className={`${style.underline} ${isVisible ? style.underlineAnimationVisible : ''}`}></span>
            </span>
        </div>
        <div className={style.textActionContainer}>
            <span className={style.textAction}>{formatTextWithLineBreaks(actionText)}</span>
        </div>
        <div className={style.actionsThemesWrapper}>
            <div className={style.actionsThemesItems}>
                <span className={style.actionsItemTitle}><FontAwesomeIcon className={style.actionsItemIcon} icon={faBasketShopping} />Accompagnements professionnels</span>
                <ul className={style.actionsItemDescription}>
                    <li><FontAwesomeIcon className={style.actionsItemIconCheck} icon={faCheck} />Création de sociétés</li>
                    <li><FontAwesomeIcon className={style.actionsItemIconCheck} icon={faCheck} />Soutien à l'installation</li>
                    <li><FontAwesomeIcon className={style.actionsItemIconCheck} icon={faCheck} />Financement</li>
                    <li><FontAwesomeIcon className={style.actionsItemIconCheck} icon={faCheck} />Expertise-comptable</li>
                    <li><FontAwesomeIcon className={style.actionsItemIconCheck} icon={faCheck} />Subventions</li>
                    <li><FontAwesomeIcon className={style.actionsItemIconCheck} icon={faCheck} />Recherches de personnels</li>
                    <li><FontAwesomeIcon className={style.actionsItemIconCheck} icon={faCheck} />Immobilier</li>
                    <li><FontAwesomeIcon className={style.actionsItemIconCheck} icon={faCheck} />Achats directs et indirects</li>
                </ul>
            </div>
            <div className={style.actionsThemesItems}>
                <span className={style.actionsItemTitle}><FontAwesomeIcon className={style.actionsItemIcon} icon={faBuilding} />Accompagnements personnels</span>
                <ul className={style.actionsItemDescription}>
                    <li><FontAwesomeIcon className={style.actionsItemIconCheck} icon={faCheck} />Retraite</li>
                    <li><FontAwesomeIcon className={style.actionsItemIconCheck} icon={faCheck} />Prévoyance</li>
                    <li><FontAwesomeIcon className={style.actionsItemIconCheck} icon={faCheck} />Ingénierie patrimoniale</li>
                    <li><FontAwesomeIcon className={style.actionsItemIconCheck} icon={faCheck} />Investissement</li>
                    <li><FontAwesomeIcon className={style.actionsItemIconCheck} icon={faCheck} />Assistance juridique</li>
                    <li><FontAwesomeIcon className={style.actionsItemIconCheck} icon={faCheck} />Transmission</li>
                </ul>
            </div>
            <div className={style.actionsThemesItems}>
                <span className={style.actionsItemTitle}><FontAwesomeIcon className={style.actionsItemIcon} icon={faLandmark} />Fiscalité</span>
                <ul className={style.actionsItemDescription}>
                    <li><FontAwesomeIcon className={style.actionsItemIconCheck} icon={faCheck} />Impôts sur les sociétés</li>
                    <li><FontAwesomeIcon className={style.actionsItemIconCheck} icon={faCheck} />Impôts sur les revenus</li>
                    <li><FontAwesomeIcon className={style.actionsItemIconCheck} icon={faCheck} />Flat Tax</li>
                    <li><FontAwesomeIcon className={style.actionsItemIconCheck} icon={faCheck} />Taxe foncière</li>
                    <li><FontAwesomeIcon className={style.actionsItemIconCheck} icon={faCheck} />TVA</li>
                    <li><FontAwesomeIcon className={style.actionsItemIconCheck} icon={faCheck} />TVS</li>
                </ul>
            </div>
            <div className={style.actionsThemesItems}>
                <span className={style.actionsItemTitle}><FontAwesomeIcon className={style.actionsItemIcon} icon={faNotesMedical} />Assurances</span>
                <ul className={style.actionsItemDescription}>
                    <li><FontAwesomeIcon className={style.actionsItemIconCheck} icon={faCheck} />Responsabilité pro</li>
                    <li><FontAwesomeIcon className={style.actionsItemIconCheck} icon={faCheck} />Protection juridique</li>
                    <li><FontAwesomeIcon className={style.actionsItemIconCheck} icon={faCheck} />Responsabilité médicale</li>
                    <li><FontAwesomeIcon className={style.actionsItemIconCheck} icon={faCheck} />Perte de revenus</li>
                    <li><FontAwesomeIcon className={style.actionsItemIconCheck} icon={faCheck} />Assurance multirisque</li>
                </ul>
            </div>
        </div>
    </div>
  );
}

export default ActionPlan;
