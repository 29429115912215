import React, { useEffect, useRef, useState } from 'react';
import style from './OurMission.module.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faBook, faSignalPerfect } from '@fortawesome/free-solid-svg-icons';

let missionText = `Nos clients, professionnels de santé, rencontrent souvent des difficultés à gérer efficacement leurs activités et à éviter des contrats désavantageux. Les longues négociations leur font perdre un temps précieux, souvent pour des résultats insatisfaisants. AHORRA leur apporte des solutions instantanées et fiables.

Nous proposons un accompagnement complet, depuis la création de votre structure libérale jusqu'à la gestion quotidienne de votre cabinet, en passant par la gestion de votre patrimoine financier et immobilier. Notre expertise vous permet de naviguer sereinement dans les complexités administratives et financières, vous libérant ainsi du temps pour vous concentrer sur ce qui compte vraiment : offrir des soins de qualité à vos patients.

Quel que soit votre domaine de spécialisation médicale, nous vous fournissons une expertise adaptée et des solutions sur mesure pour optimiser la gestion de votre cabinet.`;

function formatTextWithLineBreaks(text) {
    return text.split('\n').map((str, index, array) => (
        str !== '' ? <React.Fragment key={index}>{str}{index !== array.length - 1 && <br />}</React.Fragment> : <br key={index} />
    ));
}

function OurMission() {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const currentRef = ref.current;
        const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            });
        },
        {
            root: null,
            threshold: 0.5
        });

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, [ref]);

    return (
        <div className={style.mainContainer} ref={ref}>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', textAlign: 'center', justifyContent: 'center' }}>
                <span className={`${style.title} ${style.titleWithUnderline}`}>
                    Notre <span style={{ fontWeight: 'bold' }}>mission</span>
                    <span className={`${style.underline} ${isVisible ? style.underlineAnimationVisible : ''}`}></span>
                </span>
            </div>
            <div className={style.stepsContainer}>
                <div className={style.steps}>
                    <FontAwesomeIcon className={style.stepsIcon} icon={faBook} />
                    <span className={style.stepsTitle}>Étudier</span>
                </div>
                <div className={style.steps}>
                    <FontAwesomeIcon className={style.stepsIcon} icon={faMagnifyingGlass} />
                    <span className={style.stepsTitle}>Évaluer</span>
                </div>
                <div className={style.steps}>
                    <FontAwesomeIcon className={style.stepsIcon} icon={faSignalPerfect} />
                    <span className={style.stepsTitle}>Accompagner</span>
                </div>
            </div>
            {/* {isVisible ? (
                <div className={style.stepsContainer}>
                    <div className={`${style.steps} ${style.step1}`}>
                        <FontAwesomeIcon className={style.stepsIcon} icon={faBook} />
                        <span className={style.stepsTitle}>Étudier</span>
                    </div>
                    <div className={`${style.steps} ${style.step2}`}>
                        <FontAwesomeIcon className={style.stepsIcon} icon={faMagnifyingGlass} />
                        <span className={style.stepsTitle}>Évaluer</span>
                    </div>
                    <div className={`${style.steps} ${style.step3}`}>
                        <FontAwesomeIcon className={style.stepsIcon} icon={faSignalPerfect} />
                        <span className={style.stepsTitle}>Optimiser</span>
                    </div>
                </div>
                )} */}
            <div className={style.textMissionContainer}>
                <span className={style.textMission}>{formatTextWithLineBreaks(missionText)}</span>
            </div>
        </div>
    );
}

export default OurMission;
