import React, { useEffect, useRef, useState } from 'react';
import style from './Contact.module.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

function Contact() {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const currentRef = ref.current;
        const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            });
        },
        {
            root: null,
            threshold: 0.5
        });

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, [ref]);

    const GoToCalendly = () => {
        window.open('https://calendly.com/ahorra-conseil/30min', '_blank');
    }

  return (
    <div className={style.mainContainer} id='contact' ref={ref}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', textAlign: 'center', justifyContent: 'center' }}>
            <span className={`${style.title} ${style.titleWithUnderline}`}>
                Nous <span style={{ fontWeight: 'bold' }}>contacter</span>
                <span className={`${style.underline} ${isVisible ? style.underlineAnimationVisible : ''}`}></span>
            </span>
        </div>
        <div className={style.bothPartContainer}>
            <div className={style.onePartContainer}>
                <span className={style.textContactUs}>N'hésitez pas à prendre rendez-vous pour un premier contact.<br/><br/>Nous nous réjouissons de nos futurs échanges !</span>
            </div>
            <div className={style.onePartContainer}>
                <div className={style.infoContactContainer}>
                    <a href="tel:+33635401907" className={style.buttonContactUs}><FontAwesomeIcon icon={faPhone} style={{ marginRight: '15px', color:'rgb(197, 134, 49)' }} />06 35 40 19 07</a>
                    <a href="mailto:contact@ahorra-conseil.fr" className={style.buttonContactUs}><FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '15px', color:'rgb(197, 134, 49)' }} />contact@ahorra-conseil.fr</a>
                    <div className={style.buttonTakeMeeting} onClick={GoToCalendly}>
                        <span>Prendre rendez-vous</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Contact;
