import React, { useEffect, useRef, useState } from 'react';
import style from './About.module.css';
import Edouard from '../assets/EDOUARD.jpeg';

let aboutText = `Quelques mots de notre fondateur,

Ayant été à la tête d'entreprises de divers secteurs, j'ai conscience que le quotidien d'un entrepreneur empêche parfois de repérer les frais et autres charges inutiles.

J'ai mené ces diverses activités simultanément à mes études de gestion d'entreprise et de contrôle de gestion, avec l'obtention du grade de Master.

Au cours de mes expériences, j'ai constaté que de nombreux professionnels de santé nécessitent un accompagnement global pour naviguer dans les complexités administratives et financières. AHORRA a été fondée dans cette optique, afin de fournir un soutien complet allant de la création de la structure juridique jusqu'à la gestion patrimoniale, en passant par la gestion comptable.

Dans un contexte économique de plus en plus difficile, il est essentiel pour les structures médicales de bénéficier d'une approche intégrée pour optimiser leur gestion. En faisant confiance à AHORRA, vous vous assurez un accompagnement sur mesure qui répond aux besoins spécifiques de votre établissement, vous permettant ainsi de vous concentrer sur votre mission essentielle : offrir des soins de qualité à vos patients.`;

function formatTextWithLineBreaks(text) {
    return text.split('\n').map((str, index, array) => (
        str !== '' ? <React.Fragment key={index}>{str}{index !== array.length - 1 && <br />}</React.Fragment> : <br key={index} />
    ));
}

function About() {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const currentRef = ref.current;
        const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            });
        },
        {
            root: null,
            threshold: 0.5
        });

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, []);

    return (
    <div className={style.mainContainer} id='about' ref={ref}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', textAlign: 'center', justifyContent: 'center' }}>
            <span className={`${style.title} ${style.titleWithUnderline}`}>
                Qui <span style={{ fontWeight: 'bold' }}>sommes-nous</span>
                <span className={`${style.underline} ${isVisible ? style.underlineAnimationVisible : ''}`}></span>
            </span>
        </div>
        {/* <div className={`${style.bothPartsContainer} ${isVisible ? style.animate : ''}`}> */}
        <div className={style.bothPartsContainer}>
            <div className={style.onePart1}>
                <div className={style.inPartContainer}>
                    <img src={Edouard} alt="Edouard" className={style.imgProfile} />
                </div>
            </div>
            <div className={style.onePart2}>
                <div className={style.inPartContainer}>
                    <span className={style.textPresentation}>{formatTextWithLineBreaks(aboutText)}</span>
                </div>
            </div>
        </div>
    </div>
  );
}

export default About;
