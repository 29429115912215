import React, { useEffect, useRef, useState } from 'react';
import style from './Support.module.css';

import promoImmo from '../assets/promoImmo.jpg';
import aRep from '../assets/aRep.png';

var cessionCab = `AHORRA offre un accompagnement complet aux professionnels de santé dans leurs démarches d'achat ou de vente de cabinets médicaux et paramédicaux.

Grâce à leur expertise, ils facilitent toutes les étapes du processus, depuis l'évaluation du cabinet jusqu'à la finalisation de la transaction. Ils fournissent des conseils personnalisés, s'occupent des aspects juridiques et financiers, et assurent une transition fluide pour les praticiens et leurs patients.`;

var intermediation = `AHORRA propose également son expertise aux collectivités territoriales à la recherche de professionnels médicaux et paramédicaux pour répondre aux besoins de santé de leurs concitoyens.

Nous aidons à identifier et recruter les professionnels nécessaires pour combler les manques et assurer une couverture médicale adéquate. Grâce à notre réseau et à notre expérience, nous facilitons la mise en relation entre les collectivités et les praticiens, garantissant ainsi une réponse efficace et adaptée aux demandes locales en matière de santé.`;

function formatTextWithLineBreaks(text) {
    return text.split('\n').map((str, index, array) => (
        str !== '' ? <React.Fragment key={index}>{str}{index !== array.length - 1 && <br />}</React.Fragment> : <br key={index} />
    ));
}

function Support() {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const currentRef = ref.current;
        const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            });
        },
        {
            root: null,
            threshold: 0.5
        });

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, [ref]);

  return (
    <div className={style.mainContainer} ref={ref}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', textAlign: 'center', justifyContent: 'center' }}>
            <span className={`${style.title} ${style.titleWithUnderline}`}>
                Nos <span style={{ fontWeight: 'bold' }}>accompagnements étendus</span>
                <span className={`${style.underline} ${isVisible ? style.underlineAnimationVisible : ''}`}></span>
            </span>
        </div>
        <div className={style.lineContainer1}>
            <img src={promoImmo} alt="promoImmo" className={style.imageAccompagnement} />
            <div className={style.containerTitleAndTextAccompagnement}>
                <span className={style.titleAccompagnement}>Cession cabinet médicaux et paramédicaux</span>
                <span className={style.textAccompagnement} >
                    {formatTextWithLineBreaks(cessionCab)}
                </span>
            </div>
        </div>
        <div className={style.lineContainer}>
            <img src={aRep} alt="intermediation" className={style.imageAccompagnement} />
            <div className={style.containerTitleAndTextAccompagnement}>
                <span className={style.titleAccompagnement}>Intermédiation Collectivité territoriale / Professionnels médicaux</span>
                <span className={style.textAccompagnement} >
                    {formatTextWithLineBreaks(intermediation)}
                </span>
            </div>
        </div>
    </div>
  );
}

export default Support;
