import style from './Home.module.css';
import AhorraLogo from "../assets/BigLogoAhorra.png"

import { Link } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

function Home() {
  return (
    <div className={style.mainContainer}>
      <div className={style.logoContainer}>
        <img src={AhorraLogo} alt="Ahorra Logo" className={style.logo} />
      </div>
      <span className={style.helloText}>De la création de votre cabinet médical, en passant par son pilotage et jusqu'à la gestion de votre patrimoine personnel.<br/><br/> AHORRA vous accompagne tout au long de votre vie professionnelle.</span>
      <Link
              to="about"
              spy={true}
              smooth={true}
              offset={0}
              duration={1000}
          >
        <div className={style.homeButton}>
            Découvrez-nous
        </div>
      </Link>
      <div className={style.arrowDownContainer}>
          <Link
              activeClass="active"
              to="about"
              spy={true}
              smooth={true}
              offset={0}
              duration={1000}
              className={style.buttonOurSolutionsFromAbout}
              >
              <FontAwesomeIcon icon={faArrowDown} className={style.arrowIcon} />
          </Link>
      </div>
    </div>
  );
}

export default Home;
