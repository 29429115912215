import Home from './1Home/Home';
import About from './2About/About';
import OurMission from './3OurMission/OurMission';
import ActionPlan from './4ActionPlan/ActionPlan';
import Support from './5Support/Support';
import OurClients from './5.5OurClients/OurClients';
import Contact from './6Contact/Contact';
import Footer from './7Footer/Footer';
// import Mentions from './8Mentions/Mentions';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <>
            <Home />
            <About />
            <OurMission />
            <OurClients />
            <ActionPlan />
            <Support />
            <Contact />
            <Footer />
          </>
        } />
      </Routes>
    </Router>
  );
}

export default App;
