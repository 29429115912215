import React, { useEffect, useRef, useState } from 'react';
import style from './OurClients.module.css';

var textPresentation = `Notre portefeuille clients comprend une gamme diversifiée d'organisations, comprenant des cabinets médicaux et paramédicaux de toutes tailles, des cliniques, des vétérinaires et SSIAD. Notre expertise nous permet d'intervenir dans une multitude de domaines professionnels, offrant des solutions adaptées à chaque spécialité.`;

function formatTextWithLineBreaks(text) {
    return text.split('\n').map((str, index, array) => (
        str !== '' ? <React.Fragment key={index}>{str}{index !== array.length - 1 && <br />}</React.Fragment> : <br key={index} />
    ));
}

function Support() {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const currentRef = ref.current;
        const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            });
        },
        {
            root: null,
            threshold: 0.5
        });

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, [ref]);

  return (
    <div className={style.mainContainer} ref={ref}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', textAlign: 'center', justifyContent: 'center' }}>
            <span className={`${style.title} ${style.titleWithUnderline}`}>
                Nos <span style={{ fontWeight: 'bold' }}>clients</span>
                <span className={`${style.underline} ${isVisible ? style.underlineAnimationVisible : ''}`}></span>
            </span>
        </div>
        <div className={style.textContainer}>
            <span className={style.textPresentation}>
                {formatTextWithLineBreaks(textPresentation)}
            </span>
        </div>

        <div className={style.stepsContainer}>
            <div className={style.steps}>
                <span className={style.stepsTitle}>Professionnels médicaux</span>
            </div>
            <div className={style.steps}>
                <span className={style.stepsTitle}>Professionnels paramédicaux</span>
            </div>
            <div className={style.steps}>
                <span className={style.stepsTitle}>Cliniques</span>
            </div>
            <div className={style.steps}>
                <span className={style.stepsTitle}>Vétérinaires</span>
            </div>
        </div>
            <div className={style.lastSeparator} />
    </div>
  );
}

export default Support;
